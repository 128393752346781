import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, Scroll } from '@angular/router';
import { AccessService } from '../access';
import { UrlTokenizer } from '@logic-suite/shared/nav/breadcrumb/breadcrumb-url.tokenizer';
import { IBreadCrumb } from '@logic-suite/shared/nav/breadcrumb/breadcrumb.model';
import { titleCase } from '@logic-suite/shared/utils';
import { combineLatest } from 'rxjs';
import { AssetNode, AssetTreeService } from './asset-tree';

/**
 * Specific tokenizer for logic-suite which handles both the urls omitting the application part
 * and the asset tree node selection
 */
@Injectable()
export class NodeAndUrlTokenizer extends UrlTokenizer {
  selected: AssetNode | null = null;

  constructor(
    private dashboard: AssetTreeService,
    private access: AccessService,
    protected router: Router,
  ) {
    super(router);
  }

  override connect() {
    return combineLatest([this.dashboard.nodeSelection$, this.router.events]).subscribe(([node, event]) => {
      this.selected = node;
      const assetPath = node ? this.getCurrentPathFromAsset(node) : [];

      const routerEvent = event instanceof Scroll ? (event as Scroll).routerEvent : (event as RouterEvent);
      if (routerEvent instanceof NavigationEnd) {
        let urlPath: IBreadCrumb[] = [];
        try {
          urlPath = this.getCurrentPathFromUrl((routerEvent as NavigationEnd).urlAfterRedirects);
        } catch (ex) {
          // Do nothing
        }
        const newPath = assetPath.concat(urlPath);
        this.path$.next(newPath);
      }
    });
  }

  reset() {
    this.selected = null;
    this.dashboard.selectNode(null as unknown as AssetNode);
  }

  private getCurrentPathFromAsset(node: AssetNode): IBreadCrumb[] {
    const crumb: IBreadCrumb[] = [];
    // Calculate breadcrumbs from the hierarchy of selected tree nodes, and put them first.
    if (node) {
      const app = this.access.getCurrentApplication()?.applicationUrl.split('/').join('') ?? '';
      while (node) {
        if (!node.hidden) {
          const params = this.dashboard.calcQueryParams(node);
          crumb.splice(0, 0, {
            name: node.name,
            url: '/' + app,
            params,
          });
        }
        node = node.parent as AssetNode;
      }
    }

    return crumb;
  }

  protected getCurrentPathFromUrl(url: string): IBreadCrumb[] {
    // Calculate breadcrumbs from the url hierarchy
    const queryIdx = url.indexOf('?');
    const app = this.access.getCurrentApplication()?.applicationUrl.split('/').join('') ?? '';
    const urls = url
      .substring(0, queryIdx > 0 ? queryIdx : url.length)
      .replace(app ? `${app}` : '', '')
      .replace('//', '/')
      .split('/')
      .filter(u => !!u && u !== '?');
    // return urls.map((u, i) => ({
    //   name: titleCase(u),
    //   url: `/${urls.slice(0, i + 1).join('/')}`,
    //   params: this.paramsToObject(),
    // }));
    return urls.map((u, i) => ({
      name: titleCase(u),
      url: `/${app ? app + '/' : ''}${urls.slice(0, i + 1).join('/')}`,
      params: this.selected ? this.dashboard.calcQueryParams(this.selected) : this.paramsToObject(),
    }));
  }
}
